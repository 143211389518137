<template>
    <div>
        <b-card-code
            title="Proyectos de Interes"
            no-body
        >
            <b-table
            :fields="fields2"
            :items="items2"
            responsive
            selectable
            class="mb-0"
            :select-mode="selectMode"
            @row-selected="onRowSelected"
            >

            <!-- A virtual column -->
            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>

            <!-- A custom formatted column -->
            <template #cell(name)="data">
                <span class="text-nowrap">{{ data.value.first+' - '+ data.value.last }}</span>
            </template>

            <!-- A custom formatted column -->
            <template #cell(Popularity)="data">
                <b-progress
                :value="data.value.value"
                max="100"
                height="7px"
                :variant="data.value.variant"
                striped
                />
            </template>

            <template #cell(order_status)="data">
                <b-badge
                pill
                :variant="data.value.variant"
                >
                {{ data.value.status }}
                </b-badge>
            </template>

            <!-- A virtual composite column -->
             <template #cell(price)="data">
                {{ '$'+data.value }}
            </template>

            <template #cell(profit)="data">
                {{ data.value + ' Meses' }}
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
                {{ data.value }}
            </template>
            </b-table>

            <template #code>
            {{ codeDataRendering }}
            </template>
        </b-card-code>

        <b-card-code
            title="Mis Inversiones"
            no-body
        >
            <b-table
            :fields="fields"
            :items="items"
            responsive
            class="mb-0"
            >

            <!-- A virtual column -->
            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>

            <!-- A custom formatted column -->
            <template #cell(name)="data">
                <span class="text-nowrap">{{ data.value.first+' - '+ data.value.last }}</span>
            </template>

            <!-- A custom formatted column -->
            <template #cell(Popularity)="data">
                <b-progress
                :value="data.value.value"
                max="100"
                height="7px"
                :variant="data.value.variant"
                striped
                />
            </template>

            <template #cell(order_status)="data">
                <b-badge
                pill
                :variant="data.value.variant"
                >
                {{ data.value.status }}
                </b-badge>
            </template>

             <template #cell(investment_confirmed)="data">
                <b-badge
                pill
                :variant="data.value.variant"
                >
                {{ data.value.status }}
                </b-badge>
            </template>

            

            <!-- A virtual composite column -->
            <template #cell(price)="data">
                {{ '$'+data.value }}
            </template>

            <template #cell(profit)="data">
                {{ '$'+data.value }}
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
                {{ data.value }}
            </template>
            </b-table>

        </b-card-code>
    </div>
  
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable, BProgress, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BTable,
    BProgress,
    BBadge,
  },
  data() {
    return {
      selectMode: 'single',
      fields: [
        // A virtual column that doesn't exist in items
        { key: 'index', label: '#' },
        // A column that needs custom formatting
        { key: 'name', label: 'Proyecto' },
        { key: 'Category', label: 'Productor' },
        // A regular column
        { key: 'Popularity', label: 'Progreso' },
        // A regular column
        { key: 'order_status', label: 'Estado del Proyecto' },
        { key: 'investment_confirmed', label: 'Participación Confirmada' },
        // A virtual column made up from two fields
        { key: 'price', label: 'Inversion' },
        { key: 'profit', label: 'Ganancia' },
      ],
      items: [
        {
          name: { first: 'Proyecto ', last: 'La Esperanza' },
          Category: 'Javier Roman',
          order_status: { status: 'Recaudo', variant: 'warning' },
          investment_confirmed: { status: 'NO', variant: 'danger' },
          Popularity: { value: 70, variant: 'success' },
          price: 10000000,
          profit: 0,
        },
        {
          name: { first: 'Proyecto ', last: 'Las Orquideas 2' },
          Category: 'Pedro Fuentes',
          order_status: { status: 'Ejecución', variant: 'success' },
          investment_confirmed: { status: 'SI', variant: 'success' },
          Popularity: { value: 36, variant: 'success' },
          price: 25000000,
          profit: 0,
        },
        {
          name: { first: 'Proyecto ', last: 'Las Orquideas 1' },
          Category: 'Pedro Fuentes',
          order_status: { status: 'Finalizado', variant: 'secondary' },
          investment_confirmed: { status: 'SI', variant: 'success' },
          Popularity: { value: 100, variant: 'success' },
          price: 12000000,
          profit: 10000000,
        }
      ],
      fields2: [
        // A virtual column that doesn't exist in items
        { key: 'index', label: '#' },
        // A column that needs custom formatting
        { key: 'name', label: 'Proyecto' },
        { key: 'Category', label: 'Productor' },
        // A regular column
        { key: 'Popularity', label: 'Progreso' },
        // A regular column
        { key: 'order_status', label: 'Estado del Proyecto' },
        // A virtual column made up from two fields
        { key: 'price', label: 'Meta' },
        { key: 'profit', label: 'Duración' },
      ],
      items2: [
        {
          name: { first: 'Proyecto ', last: 'La Esperanza 2' },
          Category: 'Javier Roman',
          order_status: { status: 'Recaudo', variant: 'warning' },
          investment_confirmed: { status: 'NO', variant: 'danger' },
          Popularity: { value: 70, variant: 'success' },
          price: 250000000,
          profit: 7,
        },
        {
          name: { first: 'Proyecto ', last: 'Las Orquideas 3' },
          Category: 'Pedro Fuentes',
          order_status: { status: 'Recaudo', variant: 'warning' },
          investment_confirmed: { status: 'SI', variant: 'success' },
          Popularity: { value: 36, variant: 'success' },
          price: 125000000,
          profit: 6,
        },
      ],
    }
  },
  methods: {
    onRowSelected(items) {
      console.log(1);
    },
  },
}
</script>
